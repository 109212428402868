import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './CheckoutSidebar.module.scss';
import Button, { SECONDARY } from '../Button/Button';
import Price from '../Price/Price';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getPagePath, isCurrentPath } from '../../utils/appRoutes';
import { ReactComponent as Ideal } from '../../images/icons/ideal.svg';
import Checkbox from '../Checkbox/Checkbox';
import {
  ARD_CART_OVERVIEW,
  ARD_CHECKOUT,
  ARD_CUSTOMER_SERVICE_USER_ACCOUNT_AND_TERM,
} from '../../utils/appRoutes.definitions';
import { isProduction } from '../../utils/isProduction';
import { useAuth0Wrapper } from '../../utils/auth0';
import { isMPGTMEnabled } from '../../utils/isGTMEnabled';
import dataLayerPush from '../../utils/dataLayerPush';

const CheckoutSidebar = ({
  className,
  totalQuantity,
  totalProductsPrice,
  totalPrice,
  deliveryPrice,
  startSession,
  errors = [],
  productIds,
}) => {
  const navigate = useNavigate();
  const config = useSelector(({ config }) => config);
  const [userAgreedToProceed, setUserAgreedToProceed] = useState(false);
  const { isAuthenticated } = useAuth0Wrapper();

  const disabled = !!errors.length;

  const toggleUserAgreement = () => setUserAgreedToProceed((agreed) => !agreed);

  const goToPayment = () => {
    startSession();
    if (isMPGTMEnabled) {
      dataLayerPush({ event: 'purchase_attempt', product_ids: JSON.stringify(productIds) });
      localStorage.setItem('purchase_attempt_pids', JSON.stringify(productIds));
    }
  };

  const goToCheckout = () => {
    navigate(getPagePath(ARD_CHECKOUT));
  };

  const goToLogin = () => {
    navigate(`#${config.cmsSlugs.profile}`);
  };

  if (!totalQuantity) return null;
  const deliveryPriceAvailable = typeof deliveryPrice === 'number';
  return (
    <section className={className}>
      <div className={styles.summary}>
        <header className={styles.summaryHeader}>
          <FormattedMessage id="cart.summaryHeader" defaultMessage="Billing summary" />
        </header>
        <div className={styles.summaryInfo}>
          <span>
            <FormattedMessage id="cart.quantity" defaultMessage="Quantity" />:
          </span>
          <span className={styles.totalQuantity}>
            {totalQuantity} <FormattedMessage id="cart.items" defaultMessage="items" />
          </span>
          <span>
            <FormattedMessage id="cart.items" defaultMessage="Items" />:
          </span>
          <Price
            showDiscount={false}
            currency={process.env.REACT_APP_WEBSITE_CURRENCY}
            price={totalProductsPrice}
            classList={{ root: styles.price }}
            // previousPrice={shop.previousPrice || shop.price_original}
          />
          {!!totalPrice && (
            <span>
              <FormattedMessage id="cart.deliveryPrice" defaultMessage="Shipping cost" />:
            </span>
          )}
          {deliveryPrice === 0 && <FormattedMessage id="product.freeDelivery" defaultMessage="Free delivery" />}
          {deliveryPriceAvailable && deliveryPrice > 0 && (
            <Price
              showDiscount={false}
              currency={process.env.REACT_APP_WEBSITE_CURRENCY}
              price={deliveryPrice}
              classList={{ root: styles.price }}
            />
          )}
          {!!totalPrice && !deliveryPriceAvailable && <span className={styles.price}>-</span>}
          {totalPrice && (
            <>
              <span className={styles.totalPriceLabel}>
                <FormattedMessage id="cart.totalPrice" defaultMessage="Items" />:
              </span>
              <Price
                classList={{ root: styles.totalPrice }}
                showDiscount={false}
                currency={process.env.REACT_APP_WEBSITE_CURRENCY}
                price={totalPrice}
                // previousPrice={shop.previousPrice || shop.price_original}
              />
            </>
          )}
        </div>
        {!isAuthenticated && isCurrentPath(ARD_CART_OVERVIEW) && (
          <>
            <Button classList={{ root: styles.addToCartLarge }} onClick={goToLogin} variation={SECONDARY}>
              <FormattedMessage id="common.logIn" defaultMessage="Log in" />
            </Button>
            <Button
              classList={{ root: styles.guestCheckoutButton }}
              onClick={goToCheckout}
              variation={SECONDARY}
              disabled={disabled}
            >
              <FormattedMessage id="checkout.anonymousCheckout" defaultMessage="Guest checkout" />
            </Button>
            {!isProduction && (
              <Button
                classList={{ root: styles.addToCartLarge }}
                onClick={goToCheckout}
                variation={SECONDARY}
                disabled={false}
              >
                [Dev only: Proceed]
              </Button>
            )}
          </>
        )}
        {!!isAuthenticated && isCurrentPath(ARD_CART_OVERVIEW) && (
          <>
            <Button
              classList={{ root: styles.addToCartLarge }}
              onClick={goToCheckout}
              variation={SECONDARY}
              disabled={disabled}
            >
              <FormattedMessage id="cart.toOrder" defaultMessage="Continue to order" />
            </Button>
            {!isProduction && (
              <Button
                classList={{ root: styles.addToCartLarge }}
                onClick={goToCheckout}
                variation={SECONDARY}
                disabled={false}
              >
                [Dev only: Proceed]
              </Button>
            )}
          </>
        )}
        {isCurrentPath(ARD_CART_OVERVIEW) &&
          !!disabled &&
          errors.map((error, index) => (
            <div key={index} className={styles.cantProceedError}>
              {error}
            </div>
          ))}
        {isCurrentPath(ARD_CHECKOUT) && (
          <>
            <p className={styles.agreement}>
              <Checkbox name="billingAddressSameAsDelivery" onClick={toggleUserAgreement}></Checkbox>
              <FormattedHTMLMessage
                id="cart.agreement"
                values={{
                  link: getPagePath(ARD_CUSTOMER_SERVICE_USER_ACCOUNT_AND_TERM),
                  webShopName: config.websiteName,
                }}
              />
            </p>
            <Button
              classList={{ root: styles.addToCartLarge }}
              disabled={!userAgreedToProceed}
              onClick={goToPayment}
              variation={SECONDARY}
            >
              <FormattedMessage id="checkout.toPayment" defaultMessage="To payment" />
            </Button>
          </>
        )}
        <div className={styles.paymentMethods}>
          <Ideal height="20" />
        </div>
      </div>
    </section>
  );
};

export default CheckoutSidebar;
